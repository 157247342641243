import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Box, Title, Span } from "../../components/Core";
import GlobalContext from "../../context/GlobalContext";
import imgVideo from "../../assets/image/jpeg/hair-pop.jpg";
import HeroComponent from "../../components/videonose";



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #777777;
font-size: 1em;
line-height: 1.5;
margin-top:15px;
margin-bottom:15px;

@media screen and (max-width: 767px)
{
    
     font-size: 12px;
     text-align:center;
     
 }

`;





const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;



const PRPVideoPage = () => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      {/* <!-- VideoCTA Area --> */}

     
    <Separator />
 
  
    <Section bg="#f7f7fb"  className=""
     py={4}
   >
     <Container className=" pt-3 pb-3 pt-lg-5 pb-lg-5 position-relative">
       <Row className="align-items-center">
     
         <Col lg="6" className="order-lg-1  mt-lg-0  pb-5">
           <div
             data-aos="fade-right"
             data-aos-duration="750"
             data-aos-once="false"
           >
             <div>
         <Iwrap>

     
         <Itext as="h2">Is PRP Hair Loss Treatment <span>Safe?</span></Itext>
         </Iwrap>
               <Secondtext>
               Watch a video of our lead PRP for Hair Loss expert in action 
               </Secondtext>

              
             </div>
           </div>
         
         </Col>

         <Col lg="6" className="pl-3 pr-3 ml-0 mr-0 pb-0 mb-0 position-static order-lg-2">

           <HeroComponent
    videoUrl="https://www.youtube.com/embed/iCbFRWQfJIQ" // Specify your video URL here
    videoAlt="How Does The PRP Hair Treatment Work?"
    imgVideo={imgVideo} // Specify your image URL here
  />
         </Col>
       </Row>
  
     </Container>
    
   </Section>
   
   
      <SeparatorEnd />
        

    </>
  );
};

export default PRPVideoPage;