import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import ReactPlayer from "react-player";
import { device } from "../utils";
import trust from "../assets/image/png/trust.svg"; // Adjust the import according to your project structure

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Iline = styled.div`
  width: 6em;
  height: 1px;
  margin-right: 1em;
  background-color: rgb(171, 142, 102);

  @media screen and (max-width: 991px) {
    width: 3em;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 0px;
  color: rgb(171, 142, 102);
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'halcom', Sans Serif;
  font-weight: 500;

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h2`
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  font-size: 2.8em;
  font-family: 'halcom', Sans Serif;
  font-smooth: always;
  margin-bottom: 0.4em;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    text-align: center;
  }
`;

const IconButton = styled(Box)`
  cursor: pointer;
  font-size: 22px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${({ theme }) => theme.colors.secondary};
  width: 64px;
  height: 64px;
  @media ${device.sm} {
    width: 64px;
    height: 64px;
  }
  @media ${device.md} {
    width: 64px;
    height: 64px;
  }
`;

const ModalStyled = styled(Modal)`
  .modal-content {
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 0;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 1rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: #fff;
  @media ${device.md} {
    right: 2rem;
  }
`;

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: currentcolor;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="currentColor"
      ></path>
    </svg>
  </CloseWrapper>
);

const HeroComponent = ({ itext, secondText, videoUrl, videoAlt, imgVideo }) => {
  const gContext = useContext(GlobalContext);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const openVideoModal = (e) => {
    e.preventDefault();
    setVideoModalVisible(true);
  };

  const handleClose = () => {
    setLoading(true);
    setVideoModalVisible(false);
  };

  return (
    <>
     
        <div className=""></div>
      
          <Row className="justify-content-center align-items-center">
            <Col lg="12" md="8" sm="9" className="">
              <div className="text-center text-lg-right position-relative">
                <Box
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="300"
                >
                  <img
                    src={imgVideo}
                    alt={videoAlt}
                    className="rounded shadow"
                  />
                  <IconButton onClick={openVideoModal}>
                    <i className="icon icon-triangle-right-17-2"></i>
                  </IconButton>
                </Box>
              </div>
            </Col>
          
          </Row>
       
    

      <ModalStyled size="lg" centered show={videoModalVisible} onHide={handleClose}>
        <Modal.Body className="text-center position-relative">
          <CloseButton onClick={handleClose} />
          <PlayerWrapper>
            <ReactPlayerStyled
              url={`${videoUrl}?autoplay=1`}
              width="100%"
              height="100%"
              controls
              playing
              onReady={() => {
                setLoading(false);
              }}
            />
          </PlayerWrapper>
        </Modal.Body>
      </ModalStyled>
    </>
  );
};

export default HeroComponent;
