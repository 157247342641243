import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import trust from "../../assets/image/png/trust.svg";
import google from "../../assets/image/jpeg/google-reviews.svg";
import Perform from "../../components/numbers.js";
import { Title, Section, Box, Text } from "../../components/Core";
import { breakpoints } from "../../utils";
import imgC1 from "../../assets/image/jpeg/l1-customer2.jpg";
import imgC2 from "../../assets/image/jpeg/l1-customer3.jpg";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftItem = styled.div`
  /* Additional styling for the left item can go here */
`;

const RightItem = styled.div`
  /* Additional styling for the right item can go here */
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span {
    color: #00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 0px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const Textdesc = styled.p`
  font-size: 16px;
  color: #1a1a1a;
  line-height:26px;
  color:#777777;

  @media screen and (max-width: 991px) {
    font-size: 12px;
    line-height:20px;
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="border"
    p="30px"
    mx="20px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={2}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={0}>
        <img src={image} alt="" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Text variant="card" mb={0}>
          {name}
        </Text>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const Testimonial = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
    <Separator />
      <Section py={4} bg="#f7f7fb">
        <Container className="pt-3 pb-3">
          <Row className="pb-4">
            <Col m className="">
            <Iwrap>
                <Itext as="h1">
                  Non surgical Treatment <span>Reviews</span>
                </Itext>
              </Iwrap>
              <Secondtext>What our customers think</Secondtext>

            </Col>
          </Row>
          <Perform />
          <Row className="justify-content-center mt-3">
            <Col lg="12">
              <SliderStyled {...slickSettings}>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    
                  
                  >

                   <Textdesc> I came in for a non-surgical Rhinoplasty and really was
                      satisfied with the service I received! Mina did very well
                      at thoroughly explaining everything to me during the
                      consultation. She assessed my nose and gave me a realistic
                      idea of what could be done to achieve my desired results.
                      The facility was extremely clean and all the staff were
                      extremely friendly.</Textdesc>
                      <br />
                      <FlexContainer>
      <LeftItem>
      <Textdesc>Diana</Textdesc>
      </LeftItem>
      <RightItem>
      <img src={trust} width="50%" className="sliderrecimage2" />
      </RightItem>
    </FlexContainer>
                     
                 
                    

                  </ContentCard>
                </Box>

                 <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                
                  >
                  <Textdesc> Before attending Dermamina I knew that the service would
                      be absolutely amazing, I knew I was taking the right
                      decision by choosing Dermamina to achieve the desired look
                      I was going for. My nose looks amazing all thanks to Mina,
                      she is one of the most amazing and caring person I’ve met. The team there were amazing and such nice people they made
                      me feel welcome  .
                     
</Textdesc>
                      <br />
                      <FlexContainer>
      <LeftItem>
      <Textdesc>Amir Ahmed</Textdesc>
      </LeftItem>
      <RightItem>
      <img src={google} width="50%" className="sliderrecimage2" />
      </RightItem>
    </FlexContainer>

                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                
                  >
                  <Textdesc>Went to see Mina for a non-surgical nose job, having never
                      had any kind of filler treatment before so was
                      understandably a little nervous. However Mina was very
                      professional and friendly, explaining the procedure
                      clearly and checking if I had any questions or concerns. I
                      felt completely at ease and my results looks great - would
                      definitely recommend!</Textdesc>
                      <br />
                      <FlexContainer>
      <LeftItem>
      <Textdesc>Rachel</Textdesc>
      </LeftItem>
      <RightItem>
      <img src={trust} width="50%" className="sliderrecimage2" />
      </RightItem>
    </FlexContainer>

                  </ContentCard>
                </Box>

               


                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                
                  >
                  <Textdesc>  Absolutely amazing, I highly recommend to everyone! Always
                      Great Results, Dr. Mina is exceptional in what she does
                      and always takes great care of her patients. The whole
                      staff is excellent, friendly and welcoming always. Thank
                      you so much!</Textdesc>
                      <br />
                      <FlexContainer>
      <LeftItem>
      <Textdesc>Elsa Trojaola</Textdesc>
      </LeftItem>
      <RightItem>
      <img src={google} width="50%" className="sliderrecimage2" />
      </RightItem>
    </FlexContainer>

                  </ContentCard>
                </Box>
               
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
      <SeparatorEnd />
    </>
  );
};

export default Testimonial;

